@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-poppins overflow-x-hidden;
}

@layer components {
  /* about us start */
  .android {
    @apply flex items-center pl-[1rem] pr-[1rem] md:pl-[2rem] gap-2 md:gap-6 md:w-[210px] md:h-[60px] lg:w-[280px] lg:h-[80px] border cursor-pointer rounded-md;
  }
  .ios {
    @apply flex items-center pl-[0.4rem] pr-[1rem] gap-2 md:gap-6 md:w-[210px] md:h-[60px] lg:w-[280px] lg:h-[80px] border cursor-pointer rounded-md;
  }
  /* about us end */

  .input {
    @apply placeholder:text-[#000] placeholder:text-[10.63px] w-full md:text-[10.63px] leading-[18.09px] border border-[#333131] p-3 md:p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300;
  }

  h3 {
    @apply text-xl font-bold mt-[2rem];
  }
  .list {
    @apply cursor-pointer hover:text-slate-300;
  }
  p {
    @apply text-lg mt-2;
  }

  /* navbar styling start*/
  .navScrollMenu {
    @apply text-white text-sm md:text-[1rem];
  }
  .navMenu {
    @apply text-sm md:text-[1rem];
  }
  .navLink {
    @apply relative;
  }
  .navLink::after {
    @apply absolute w-0 h-0.5 block mt-1 bg-orange-500 transition-all duration-300 ease-in-out;
    content: "";
    right: 0;
  }
  .navLink:hover::after {
    @apply w-full left-0;
  }
  /* navbar styling end*/

  /* home styling start*/
  .video {
    @apply h-[20rem] md:h-[35rem] lg:h-[42rem] object-cover rounded-[1.6rem] md:rounded-[2.6rem] lg:rounded-[3.2rem];
  }
  .left-img {
    @apply h-[17rem] md:h-[34rem] lg:h-[40rem];
  }
  .right-img {
    @apply h-[17rem] md:h-[34rem] lg:h-[40rem];
  }
  /* home styling end*/

  /* hr portal */
  .hr-portal-label {
    @apply text-[#24336B] text-[12.4px] font-medium;
  }
  .post-details-input {
    @apply placeholder:text-[#00000095] placeholder:text-[10.63px] w-full md:text-[10.63px] leading-[24px] border border-[#333131] py-3 px-3 md:px-6 md:py-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-700 transition duration-300 mt-2 md:mt-4;
  }

  /* jobhunt start */
  .jobhunt-android {
    @apply flex items-center pl-[1rem] pr-[1rem] md:pl-[2rem] gap-2 md:gap-6 md:w-[310px] md:h-[80px] lg:w-[404px] lg:h-[120px] border cursor-pointer rounded-md;
  }
  .jobhunt-ios {
    @apply flex items-center pl-[1rem] pr-[1rem] gap-2 md:gap-6 md:w-[310px] md:h-[80px] lg:w-[404px] lg:h-[120px] border cursor-pointer rounded-md;
  }
  /* jobhunt end */

  /* preview-label start */
  .preview-label {
    @apply text-[12px] font-normal text-[#000000a4];
  }
  .preview-field {
    @apply text-[12px] font-medium text-[#000];
  }

  /* rn-service-2 */

  .headings {
    @apply text-[18px] font-semibold lg:text-[30px];
  }

  .para {
    @apply text-[10px] lg:text-[20px];
  }

  .card-width {
    @apply w-[183px] border border-lcsBlue rounded-lg bg-white;
  }

  .card-sm-text {
    @apply text-[9px] m-0 leading-[14px];
  }

  /*  rn-service-3 */

  .headingsBlue {
    @apply text-[22px] font-semibold lg:text-[40px] text-customBlue;
  }

  .subText {
    @apply text-[14px] lg:text-[22px];
  }
}
